/* reseting react tostify styles */

.Toastify__toast--info {
  background-color: #e0eefb !important;
}

.Toastify__toast--info .Toastify__close-button {
  color: #62aced;
}

.Toastify__toast--info .Toastify__progress-bar {
  background-color: #62aced !important;
}

.Toastify__toast--success {
  background-color: #e6f4e7 !important;
}

.Toastify__toast--success .Toastify__close-button {
  color: #82c785;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #82c785 !important;
}

.Toastify__toast--warning {
  background-color: #fff1db !important;
}

.Toastify__toast--warning .Toastify__close-button {
  color: #ffb74d;
}

.Toastify__toast--warning .Toastify__progress-bar {
  background-color: #ffb74d !important;
}

.Toastify__toast--error {
  background-color: #ffe7e0 !important;
}

.Toastify__toast--error .Toastify__close-button {
  color: #ff8964;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #ff8964 !important;
}

.Toastify__toast--default {
  /* background-color: #e5e892 !important; */
  background-color: #112e46 !important;
}

.Toastify__toast--default .Toastify__close-button {
  /* color: #c4c75b; */
  color: #112e46 !important;
}

.Toastify__toast--default .Toastify__progress-bar {
  background: none;
  /* background-color: #c4c75b !important; */
  background-color: #112e46 !important;
}

.Toastify__toast-body {
  color: #4a4a4a;
  /* color: #FFF; */
}

/*css for react-redux loader*/
.loading {
  background-color: #24b57a;
  height: 5px;
  position: absolute;
  border-radius: 10px;
}

.spinner {
  width: 15px;
  height: 15px;
  position: relative;
  margin: auto;
  display: inline-block;
  top: 4px;
  padding-top: 1px;
}

.spinner::before,
.spinner::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.spinner::before {
  border: 2px dotted #000;
  animation: spin 2s linear infinite;
}

.spinner::after {
  border: 2px solid transparent;
  border-top-color: #000;
  animation: spin 1s linear infinite reverse;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}
