@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  font-style: normal;
  src: url("./assets/avenir/avenir-black.woff2") format("woff2");
}
@font-face {
  font-family: "Avenir";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/avenir/avenir-heavy.woff2") format("woff2");
}
@font-face {
  font-family: "Avenir";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/avenir/avenir-medium.woff2") format("woff2");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 600;
  font-style: normal;
  src: url("./assets/avenir-next/avenir-next-demi.ttf") format("truetype");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/avenir-next/avenir-next-medium.ttf") format("truetype");
}

html {
  height: 100%;
  scroll-behavior: smooth;
}
* {
  scroll-behavior: smooth;
}
body {
  font-family: AvenirNext, sans-serif;
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

.paginationContainer a {
  padding: 10px;
  margin: 8px;
}

.remove_number_input_arrow::-webkit-outer-spin-button,
.remove_number_input_arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
